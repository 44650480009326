import { Button } from '@mui/material';
import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import isBrowser from '../../utils/isBrowser';

import BrandLogo from '../../assets/brand_logo_big.png';

import * as styles from './Navbar.module.scss';

function Navbar() {
  const location = useLocation();

  return (
    <nav className={styles.container}>
      <img src={BrandLogo} alt='Tracy' height={60} />

      {location.pathname === '/' && (
        <Button
          className={styles.btn}
          variant='contained'
          onClick={() => {
            isBrowser &&
              window.gtag('event', 'click', {
                category: 'Button',
                action: 'Click',
                label: 'Contact Us Button',
              });

            navigate('/contact');
          }}
        >
          Contact Us
        </Button>
      )}
    </nav>
  );
}

export default Navbar;
