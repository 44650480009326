import React, { createContext, useState, useEffect } from 'react';
import isBrowser from '../utils/isBrowser';

const INITIAL_STATE = {
  adsProvider: { value: '', error: '' },
  email: { value: '', error: '' },
  websiteUrl: { value: '', error: '' },
  name: { value: '', error: '' },
  isConsent: { value: 'false', error: '' },
};

const UserContext = createContext({
  ...INITIAL_STATE,
  handleChange: () => {},
  handleError: () => {},
});

const UserContextProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(
    isBrowser && JSON.parse(sessionStorage.getItem('tracy'))
      ? {
          ...JSON.parse(sessionStorage.getItem('tracy')),
          isConsent: { value: 'false', error: '' },
        }
      : INITIAL_STATE
  );

  useEffect(() => {
    if (isBrowser) {
      sessionStorage.setItem('tracy', JSON.stringify(userDetails));

      window.onunload = function () {
        sessionStorage.removeItem('tracy');
      };
    }
  }, [userDetails]);

  const handleChange = event => {
    const { value, name } = event.target;
    setUserDetails(d => ({ ...d, [name]: { ...d[name], value } }));
  };

  const handleError = errObj => {
    for (let x in userDetails) {
      setUserDetails(d => ({ ...d, [x]: { ...d[x], error: errObj[x] ?? '' } }));
    }
  };

  return (
    <UserContext.Provider value={{ ...userDetails, handleChange, handleError }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
